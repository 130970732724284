@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.section {
  background-color: $btnDefaultColor;
}

.actionsWrapper {
  padding-left: 30px;
  padding-right: 30px;
}
