@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.tabContent {
  min-height: 500px;
  padding: 70px 120px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mqMaxWidth($screenL) {
    padding: 70px 40px;
  }

  @include mqMaxWidth($screenM) {
    padding: 70px 20px;
  }

  @include mqMaxWidth($screenS) {
    padding: 30px 40px;
    flex-direction: column;
  }
}

.featuresList {
  max-width: 420px;
  margin-right: 15px;
  flex-shrink: 0;

  @include mqMaxWidth($screenM) {
    margin-right: 20px;
    max-width: 300px;
  }

  @include mqMaxWidth($screenS) {
    order: 2;
    margin-top: 30px;
    margin-right: 0;
    max-width: 100%;
  }
}

.screenshot {
  max-width: 710px;
  width: 100%;

  @include mqMaxWidth($screenS) {
    max-width: 100%;
    height: auto;
  }
}

.screenshotImageWrp {
  display: flex;

  box-shadow: 0 0 50px rgba(107, 120, 113, 0.15);
  border-radius: 5px;
}

.multipleImagesWrp {
  display: flex;
  justify-content: space-between;
}

.multipleScreenshotWrp {
  max-height: 447px;
  box-shadow: 0 0 50px rgba(107, 120, 113, 0.15);
  border-radius: 5px;

  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  @include mqMaxWidth($screenS) {
    max-width: 50%;

    height: auto;
  }
}

.screenshotWrp {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 710px;
}

.caption {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #616161;
  margin-top: 30px;
  text-align: center;

  @include mqMaxWidth($screenS) {
    margin-top: 20px;
    font-size: 11px;
    line-height: 15px;
  }
}
