@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/dealvdr/variables.scss";

.actionsWrp {
  text-align: center;
}

.buttonsDivider {
  font-size: 18px;
  line-height: 25px;

  letter-spacing: 0.01em;

  color: #fff;

  @include mqMaxWidth($screen640) {
    display: none;
  }
}

.bookDemoButton {
  margin-right: 20px;
  transition: background-color $animationTime + ms;

  @include mqMaxWidth($screen640) {
    margin: 0;
  }
}

.getStartedButton {
  margin-left: 20px;
  transition: background-color $animationTime + ms;

  @include mqMaxWidth($screen640) {
    margin: 10px 0 0;
  }
}
