@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/dataroom/ui/components/Landing/dealvdr/variables.scss";

.tabsList {
  display: flex;
  position: relative;
  justify-content: center;
  border-bottom: 1px solid $secondary2Color;
}

.tabsWrp {
  display: flex;
  position: relative;
  justify-content: center;
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: $secondaryColorHover;
  transition: left 250ms;
  transform: translateX(-50%);
}

.tabItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $actionColor;
  cursor: pointer;
  position: relative;

  padding: 20px 0;
  margin-right: 70px;

  .tabLabel {
    transition: color $animationTime + ms;
  }

  &:nth-last-of-type(2) {
    margin-right: 0;
  }

  svg {
    transition: fill $animationTime + ms;

    fill: $actionColor;
  }

  &:hover {
    color: $titleColor;

    svg {
      fill: $secondaryColorHover;
    }
  }

  &.isTabActive {
    cursor: default;
    color: $titleColor;

    svg {
      fill: $secondaryColorHover;
    }
  }

  @include mqMaxWidth($screenM) {
    padding-top: 0;
    margin-right: 42px;
  }
}

.tabItemIcon {
  margin-bottom: 20px;

  @include mqMaxWidth($screenM) {
    margin-bottom: 0;
  }
}

.tabLabel {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;

  @include mqMaxWidth($screenM) {
    display: none;
  }
}
