@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$headerHeight: 66px;
$animationTime: 200;
$menuLinkColorBorder: rgba(255, 255, 255, 0.1);

.header {
  width: 100%;
  position: relative;
  z-index: 6;
  top: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms, visibility 200ms;
}

.headerFixed {
  position: fixed;
  top: 0;
}

.headerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #494949;
  opacity: 0.5;
  z-index: 5;
}

.headerWrp {
  background: $ColorGrayscaleWhite;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.animatedHeader {
  transition: opacity 200ms, visibility 200ms;
}

.isHeaderHidden {
  opacity: 0;
  visibility: hidden;
}

.headerContainer {
  max-width: 1440px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  height: 66px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.headerMenu {
  display: block;

  @include mqMaxWidth(870px) {
    .menuLink {
      display: none;
    }
  }
}

.burger {
  cursor: pointer;
  display: none;
  vertical-align: top;
  margin-top: 24px;
  margin-right: 33px;

  @include mqMaxWidth(870px) {
    display: inline-block;
  }

  @include mqMaxWidth($screen568) {
    margin-right: 23px;
  }
}

.logo {
  display: inline-block;
  margin-top: 15px;

  @include mqMaxWidth($screen568) {
    overflow: hidden;
  }
}

.logoImage {
  width: 172px;
  height: 36px;

  @include mqMaxWidth($screen568) {
    position: relative;
    left: -83px;
  }

  @include mqMaxWidth($screenXXs) {
    position: relative;
    left: -68px;
    width: 150px;
  }
}

.menu {
  display: inline-block;
  margin-left: 15px;
  vertical-align: top;

  @include mqMaxWidth(870px) {
    display: none;
  }
}

.menuLink {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $ColorGrayscaleGray1;
  padding: 23px 15px 24px;
  letter-spacing: 0.01em;

  &:hover {
    color: $ColorGrayscaleBlack;
  }
}

.headerActions {
  line-height: 66px;
}

.headerUserActions {
  display: inline-block;

  .menuCustomButton {
    margin-left: 10px;
  }

  @include mqMaxWidth($screen568) {
    .menuCustomButton {
      display: none;
    }
  }
}

.menuButton {
  margin-right: 0;
}

.menuLoginButton {
  transition: background-color $animationTime + ms, color $animationTime + ms;

  &:hover {
    background: $actionColor;
    border-color: $actionColor;
    color: $ColorGrayscaleWhite;
  }
}

.menuCustomButton {
  transition: background-color $animationTime + ms;
}

.mobileActionsWrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .menuButton {
    width: 100%;
    margin-top: 10px;
  }

  .menuLoginButton {
    border-color: $ColorGrayscaleWhite;
    color: $ColorGrayscaleWhite;

    &:hover {
      background: $ColorGrayscaleWhite;
      color: $actionColor;
    }
  }
}

.isMenuLinkActive {
  font-weight: 400;
}

.menuLink:hover,
.isMenuLinkActive {
  color: $ColorGrayscaleBlack;

  &:hover {
    color: $ColorGrayscaleBlack;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 28px;
    background: $ColorGrayscaleBlack;
    height: 3px;
    transform: translate3d(-50%, 0, 0);
  }
}

.divider {
  width: 1px;
  height: 46px;
  background-color: $btnDefaultColor;
  vertical-align: top;
  display: inline-block;
  margin: 10px 10px 0 40px;

  @include mqMaxWidth(870px) {
    display: none;
  }
}

.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  background: #2d2d2d;
  width: 90%;
  max-width: 340px;
  left: -350px;
  margin: 0;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: left 300ms;

  @include mqMaxWidth(870px) {
    display: flex;

    &.menuOpened {
      left: 0;
    }

    .menuLink {
      border-top: 1px solid $menuLinkColorBorder;
      width: 100%;
      color: $ColorGrayscaleWhite;

      &:last-of-type {
        border-bottom: 1px solid $menuLinkColorBorder;
      }
    }

    .menuLink:hover,
    .isMenuLinkActive {
      color: $ColorGrayscaleWhite;
      background: rgba(0, 0, 0, 0.2);

      &:after {
        content: none;
      }
    }
  }
}

.mobileHeaderMenu {
  padding: 0 20px;
  margin-bottom: 20px;
}

.closeMenuButton {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-top: 24px;
  margin-right: 24px;

  svg {
    fill: $ColorGrayscaleWhite;
  }
}
