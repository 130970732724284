@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.feature {
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  @include mqMaxWidth($screen640) {
    margin-bottom: 10px;
  }
}

.featureIcon {
  display: table-cell;
  width: 16px;
  height: 16px;
  vertical-align: middle;

  i {
    fill: #6fcf97;
  }
}

.featureTitle {
  padding-left: 20px;

  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;

  color: $baseTextColor;

  @include mqMaxWidth($screenS) {
    font-size: 16px;
    line-height: 20px;
  }

  @include mqMaxWidth($screen640) {
    font-size: 16px;
    line-height: 20px;

    padding-left: 10px;
  }
}
