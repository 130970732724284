@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.section {
  position: relative;
}

.paddingTop {
  padding-top: 100px;

  @include mqMaxWidth($screen640) {
    padding-top: 50px;
  }
}

.paddingBottom {
  padding-bottom: 100px;

  @include mqMaxWidth($screen640) {
    padding-bottom: 50px;
  }
}
