@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "../variables.scss";

.wrapper {
  margin-bottom: 50px;

  @include mqMaxWidth($screen640) {
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.title {
  color: $titleColor;
  font-family: $baseFontFamily;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  line-height: 43px;

  @include mqMaxWidth(768px) {
    font-size: 30px;
    line-height: 33px;
  }

  @include mqMaxWidth($screen640) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
  }
}

.description {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: $baseTextColor;
  text-align: center;
  margin: 0 auto;
  max-width: 850px;
  line-height: 25px;
  letter-spacing: 0.01em;

  a {
    color: $baseTextColor;
  }

  @include mqMaxWidth($screen640) {
    font-size: 13px;
    line-height: 18px;
  }
}
